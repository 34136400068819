import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_DONATION_URLS} from "../../PanelConstants.js";
import {iFrameMerchantService} from "../../../services/IframeMerchantService.js";
import {Money} from "../../../../stem-core/src/localization/Money.js";
import {iFrameUserDataService} from "../../../services/IFrameUserDataService.js";
import {Messages} from "../../../Messages.js";
import {CycleUnit} from "../../../../client/state/RecurringPaymentStoreObject.js";
import {DonationOfferStore} from "../../../../client/state/DonationOfferStore.js";
import {iFrameState} from "../../../services/IFrameState.js";

export class DonationDetailsFlowStep extends BaseFlowStep {
    frequency = CycleUnit.ONE_TIME;
    donationAmount = 0;
    donorCoversFees = false;

    getDefaultFields() {
        const donationOffer = this.getDonationOffer();
        const activeDonation = iFrameUserDataService.getActiveRecurringDonation();

        this.frequency = CycleUnit.ONE_TIME;

        if (donationOffer && !activeDonation) {
            if (!donationOffer.hasAmountChoice()) {
                this.donationAmount = donationOffer.predefinedAmounts[0];
            }
            this.frequency = donationOffer.defaultFrequency || donationOffer.getFrequencyOptions()[0];
        }

        if (!this.donationAmount) {
            const {predefinedAmounts, defaultPredefinedAmountIndex} = donationOffer;
            this.donationAmount = predefinedAmounts[defaultPredefinedAmountIndex];
        }
    }

    updateFromDonation(activeDonation) {
        this.donorCoversFees = activeDonation.coversFees;
        this.frequency = activeDonation.frequency;
        this.donationAmount = activeDonation.computeAmount();
    }

    isValid() {
        return !!this.donationAmount;
    }

    getDonationOffer() {
        const {merchantId} = iFrameMerchantService;
        return DonationOfferStore.getAvailable().find(offer => offer.merchantId === merchantId);
    }

    getCurrency() {
        return this.getDonationOffer().getCurrency();
    }

    // Various things the donor can do, computed from the donation offer
    // The frequencies are the only ones the donor can do now (so skipping recurring if one is enabled)
    getOptionsForDonor() {
        const donationOffer = this.getDonationOffer();
        const {minAmount, maxAmount, allowCustomAmount} = donationOffer;
        const activeRecurringDonation = iFrameUserDataService.getActiveRecurringDonation();
        const frequencyOptions = donationOffer.getFrequencyOptions()
            .filter(frequency => frequency.isOneTime() || !activeRecurringDonation);

        const {allowUserToCoverFees} = iFrameState.iframeParams; // TODO @flow strange it's from the iframeParams

        const predefinedAmounts = Array.from(donationOffer.predefinedAmounts); // Make a copy to not edit the store object
        if (allowCustomAmount) {
            predefinedAmounts.push(null);
        }

        return {
            donationOffer,
            currency: donationOffer.getCurrency(),
            frequencyOptions,
            allowOneTime: frequencyOptions.find(frequency => frequency.isOneTime()),
            allowRecurring: frequencyOptions.find(frequency => !frequency.isOneTime()),
            allowUserToCoverFees,
            minAmount,
            maxAmount,
            predefinedAmounts,
        }
    }

    isDonationAmountCustom() {
        const {predefinedAmounts} = this.getDonationOffer();
        for (const amount of predefinedAmounts) {
            if (amount === this.donationAmount) {
                return false;
            }
        }
        return true;
    }

    getDonationFee() {
        const donationOffer = this.getDonationOffer();
        const amount = donationOffer.computeFeeFromAmount(this.donationAmount);
        return new Money(amount, this.getCurrency());
    }

    // TODO This should return a Money as well
    getTotalAmount() {
        return this.donationAmount + (this.donorCoversFees ? this.getDonationFee().amount : 0);
    }

    // TODO @branch @flow is this really needed?
    formatAmount(amount) {
        return new Money(amount, this.getCurrency());
    }

    getPayload() {
        return {
            amount: this.getTotalAmount(),
            frequency: this.frequency,
            coversFees: this.donorCoversFees,
        };
    }

    getDonateComponentButtonLabel() {
        const amount = this.getTotalAmount();
        const {frequency} = this;
        if (!amount || !frequency) {
            return Messages.donate;
        }
        const formattedAmount = this.formatAmount(amount);
        const formattedFrequency = frequency.isOneTime() ? "" : "/" + frequency.name.toLowerCase();
        return Messages.donateInlineFlowButton(formattedAmount, formattedFrequency);
    }

    getEditDonationComponentButtonLabel() {
        return !this.donationAmount ? Messages.update : Messages.editDonationInlineFlowButton(
            this.formatAmount(this.getTotalAmount()),
            this.frequency.name.toLowerCase()
        );
    }

    showPanel() {
        Router.changeURL(INLINE_DONATION_URLS.donate);
    }
}
