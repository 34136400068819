import {PaymentMethodStore} from "../../../../client/state/PaymentMethodStore.js";
import {iFrameMerchantService} from "../../../services/IframeMerchantService.js";
import {encryptJSON, ensureNaClScript} from "../../../../client/crypto/encryptJSON.js";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {ApiErrors} from "../../../../client/connection/ApiErrors.js";
import {Messages} from "../../../Messages.js";
import {iFrameUserDataService} from "../../../services/IFrameUserDataService.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {PAYMENT_DETAILS_PANEL_URL} from "../../PanelConstants.js";
import {BaseFlowStep} from "./BaseFlowStep.jsx";


export class PaymentMethodFlowStep extends BaseFlowStep {
    continueLabel = Messages.finalizePayment;
    scope = PaymentMethodStore.Scope.PUBLIC;
    cardHolderName = ""; // The IDE seems to fail to interpret this as being used, but it actually is.
    showSaveCardCheckbox = true; // If you want to allow an iframe option to override this, track where this variable is used
    entry = null;
    savePaymentMethod = true;
    retryPayment = null;
    paymentError = null;
    altPayData = null; // AmazonPay or PayPal

    haveOptionsAvailable() {
        return !iFrameMerchantService.isCDSPaymentMethod() && (this.selectedValue || iFrameUserDataService.getExtraPaymentMethodCount())
    }

    getDefaultFields() {
        this.selectedValue = PaymentMethodStore.getDefault();
    }

    getFieldsToReset() {
        const obj = super.getFieldsToReset();
        if (this.selectedValue) {
            delete obj.cardHolderName;
        }
        return obj;
    }

    async beforeShowPanel() {
        // Optimization for early loading
        if (iFrameMerchantService.isCDSPaymentMethod()) {
            ensureNaClScript().then();
        }
    }

    showPanel() {
        Router.changeURL(PAYMENT_DETAILS_PANEL_URL);
    }

    async extractPaymentInfoForCDS() {
        const {altPayData} = this;
        if (altPayData) {
            return `${altPayData.provider.value}-${altPayData.orderId}`
        }
        const {cardNumber, cardExpiry, cardCvv} = (this.selectedValue || {});
        return encryptJSON({
            card_number: cardNumber,
            card_expiry: cardExpiry,
            card_cvv: cardCvv,
        }, authService.appSettings.blinkPaymentMethodEncryptionPublicKey);
    }

    async getPayload() {
        if (iFrameMerchantService.isCDSPaymentMethod()) {
            const paymentInfo = await this.extractPaymentInfoForCDS();
            return {paymentInfo};
        }
        return {
            preferredPaymentMethodId: this.selectedValue?.id, // TODO @cleanup rename to paymentMethodId
        }
    }

    // Same as the regular payload, except without CDS paymentInfo for instance.
    maskPayload(payload) {
        payload = {...payload};
        if (payload.paymentInfo) {
            delete payload.paymentInfo;
        }
        return payload;
    }

    getPaymentErrorMessage() {
        if (this.paymentError && this.paymentError.code === ApiErrors.CDS_NEW_ORDER_ERROR) {
            return "";
        }
        return this.paymentError?.message || "";
    }

    setAltPayOrderId(provider, orderId) {
        // If provider is null, clear the field
        const altPayData = provider && {
            provider,
            orderId,
        }
        this.update({altPayData});
    }
}
