import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Messages} from "../../Messages.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {BlinkInput} from "../../ui/Input.jsx";
import {AuthHelper} from "../../AuthHelper.js";
import {authFormService} from "../../services/AuthFormService.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {authService} from "../../../client/connection/services/AuthService.js";
import {isIframe} from "../../Utils.js";
import {iFrameState} from "../../services/IFrameState.js";
import {ExternalAuthButtons} from "../../../core/external-auth/ExternalAuthButtons.jsx";
import {TermsMessage} from "../../website/components/TermsMessage.jsx";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";
import {TNR_ALIAS} from "../../../blink-sdk/Constants.js";
import {PoweredByBlink} from "../../panel/panel-elements/PoweredByBlink.jsx";
import {BlinkButton} from "../../../core/ui/Button.jsx";


export class AuthStartPanelStyle extends StyleSheet {
    @styleRule
    input = {
        marginBottom: 6,
    };

    @styleRule
    button = {
        width: "100%",
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        padding: "12px !important",
        textAlign: "center",
        margin: "18px 0",
    };

    @styleRule
    headerElement = {
        padding: "0 36px",
    };

    @styleRule
    message = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        textAlign: "center",
        width: "100%",
        margin: "12px 0",
    };

    @styleRule
    title = {
        fontSize: 20,
        textAlign: "center",
        marginBottom: 18,
    };

    @styleRule
    footerSection = {
        borderTop: () => "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR,
    }
}

// The first page in any auth flow: select either external auth or enter an email
@registerStyle(AuthStartPanelStyle)
export class AuthStartPanel extends UI.Element {
    emailInputError = null;

    getDefaultOptions() {
        return {
            useSecureCode: false,
            createUserAutomatically: true,
            inputPlaceholder: "",
            showFooter: true,
            externalAuthProviders: iFrameState.authSocialProviders,
        }
    }

    render() {
        return [
            this.getHeaderSection(),
            this.getEmailInputSection(),
            this.getFooterSection(),
        ];
    }

    getHeaderSection() {
        const {styleSheet} = this;
        const {externalAuthProviders} = this.options;

        return [
            Messages.authenticationPanelTitle.toString().trim() &&
            <div className={styleSheet.title}>
                {Messages.authenticationPanelTitle}
            </div>,

            externalAuthProviders.length > 0 ? [
                <div className={styleSheet.message}>{Messages.continueWithExternalAuth}</div>,
                <ExternalAuthButtons
                    className={styleSheet.headerElement}
                    externalAuthProviders={externalAuthProviders}
                />,
                <div className={styleSheet.message}>
                    {Messages.orContinueWithEmailAddress}
                </div>,
            ] : <div className={styleSheet.message}>
                {Messages.continueWithEmail}
            </div>,
        ];
    }

    getEmailInputSection() {
        const {styleSheet, emailInputError} = this;
        const {inputPlaceholder} = this.options;
        const {registrationMail} = authFormService;

        return [
            <BlinkInput
                ref="emailInput"
                className={styleSheet.input}
                label={Messages.emailAddress}
                initialValue={registrationMail}
                error={emailInputError}
                onFocus={() => this.clearErrors()}
                inputAttributes={{type: "email", testId: "authEmail", placeholder: inputPlaceholder}}
                onEnter={() => this.requestEmailCode()}
                onChange={() => this.onEmailInputChange()}/>,

            <BlinkButton
                testId="continueWithCode"
                ref="continueWithCode"
                label={Messages.continue}
                className={styleSheet.button}
                disabled={!this.canRequestEmailCode()}
                onClick={() => this.requestEmailCode()}/>
        ]
    }

    getFooterSection() {
        const {styleSheet} = this;
        const {showFooter} = this.options;
        if (!showFooter) {
            return null;
        }

        // TODO the terms text & links should be configurable.
        const extraTermsMessageOptions = {};
        if (iFrameMerchantService.isMerchant(TNR_ALIAS)) {
            extraTermsMessageOptions.termsLink = "https://newrepublic.com/pages/terms-and-conditions";
            extraTermsMessageOptions.policyLink = "https://newrepublic.com/pages/privacy";
        }

        return [
            // TODO @flow add the back button (styled)
            // Flow.auth.renderBackButton(),
            <div className={styleSheet.footerSection}>
                <TermsMessage preMessage={Messages.termsPreMessage} {...extraTermsMessageOptions}/>
                <PoweredByBlink/>
            </div>
        ];
    }

    onEmailInputChange() {
        this.clearErrors();
        this.redraw();
    }

    getRedirectUrl() {
        if (isIframe()) {
            return iFrameState.merchantPageUrl;
        }

        return window.location.origin;
    }

    getRequestPayload() {
        return {
            firstName: "",
            lastName: "",
            email: this.emailInput.getValue().trim(),
            redirectUrl: this.getRedirectUrl(),
            withSignup: this.options.createUserAutomatically,
            secure: this.options.useSecureCode,
        };
    }

    canRequestEmailCode() {
        return this.hasFilledEmail();
    }

    async requestEmailCode() {
        if (!this.canRequestEmailCode()) {
            return;
        }

        // Hide the mobile keyboard.
        this.continueWithCode?.node.blur();
        try {
            const requestPayload = this.getRequestPayload();
            await AuthHelper.requestLoginCode(requestPayload);
            if (!authService.isAuthenticated()) {
                this.options.onEmailCodeRequested();
            }
        } catch (error) {
            console.log(error);
            this.emailInputError = error.message;
            this.redraw();
        }
    }

    hasFilledEmail() {
        return this.emailInput?.getValue();
    }

    clearErrors() {
        if (!this.emailInputError) {
            return;
        }
        this.emailInputError = null;
        this.redraw();
    }

    onMount() {
        super.onMount();

        const fillDataAndRedraw = () => {
            const {registrationMail} = authFormService;

            // We might have an email or a password saved in the app state previously by what the user typed on the auth form;
            // If the user hasn't typed anything in any current input, fill it with that value he previously filled.
            if (registrationMail && !this.hasFilledEmail()) {
                this.emailInput.setValue(registrationMail);
            }
        };
        this.attachChangeListener(authFormService, fillDataAndRedraw);

        this.emailInput.focus();
        this.addListener("urlEnter", () => this.emailInput.focus());
        this.addListener("urlExit", () => authFormService.setRegistrationMail(this.emailInput.getValue()));
    }
}
